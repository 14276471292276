import { useCallback } from 'react';

interface IReturn {
    get: () => string;
    set: (value: string) => void;
    remove: () => void;
}

export const useLocalStorageItem = (storageKey: string): IReturn => {

    const get = useCallback(() => {
        return window.localStorage.getItem(storageKey);
    }, [storageKey]);

    const set = useCallback((value: string) => {
        return window.localStorage.setItem(storageKey, value);
    }, [storageKey]);

    const remove = useCallback(() => {
        return window.localStorage.removeItem(storageKey);
    }, [storageKey]);

    return {
        get,
        set,
        remove,
    };
};
