import { Box } from '@mui/material';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { useRecoilState } from 'recoil';
import React, { FC, ReactNode } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { appUserIsLoggedIn } from 'modules/state/app/state';
import { ESocialLogin } from 'components/loginModal/enums/ESocialLogin';
import { SocialLogin as SocialLoginConstant } from 'components/loginModal/constants/SocialLogin';
import { SocialLoginItem } from 'components/layout/header/components/navbar/common/links/user/loggedOut/menu/menuItem/SocialLoginItem';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';
import { getLoginEvent } from 'modules/gtmEvents/helpers/events/common/headerEventsHelper';
import {EAction} from 'modules/gtmEvents/enums/header/EAction';
import {PathNameToActionRecord} from 'modules/gtmEvents/records/header/PathNameToActionRecord';
import {usePathname} from 'next/navigation';
import {IGtmEvent} from 'modules/analytics/IAnalytics';
import {getResetEcommerceEvent} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';

interface ISocialLoginMenu {
    children: ReactNode;
}

export const SocialLoginMenu: FC<ISocialLoginMenu> = ({ children }) => {
    const pathname = usePathname();
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();
    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const action: EAction = PathNameToActionRecord[pathname || ''];


    const handleClose = () => setAnchorEl(null);
    const handleClick = (currentTarget) => {
        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getLoginEvent(isAdvertiserLoggedIn, action),
        ];

        gtmSendEvent(...events);
        setAnchorEl(currentTarget);
    };

    return (
        <>
            <Box
                id="fade-button"
                aria-haspopup="true"
                sx={{ cursor: 'pointer' }}
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => handleClick(e.currentTarget)}
                aria-controls={open ? 'fade-menu' : undefined}
            >
                {children}
            </Box>

            <Menu
                open={open}
                id="fade-menu"
                anchorEl={anchorEl}
                onClose={handleClose}
                TransitionComponent={Fade}
                MenuListProps={{ 'aria-labelledby': 'fade-button' }}
            >
                <MenuItem>
                    <SocialLoginItem
                        {
                            ...{
                                ...SocialLoginConstant[ESocialLogin.AZET],
                                position: ELoginPosition.HEADER
                            }
                        }
                    />
                </MenuItem>
                <MenuItem>
                    <SocialLoginItem
                        {
                            ...{
                                ...SocialLoginConstant[ESocialLogin.FACEBOOK],
                                position: ELoginPosition.HEADER
                            }
                        }
                    />
                </MenuItem>
                <MenuItem>
                    <SocialLoginItem
                        {
                            ...{
                                ...SocialLoginConstant[ESocialLogin.GOOGLE],
                                position: ELoginPosition.HEADER
                            }
                        }
                    />
                </MenuItem>
            </Menu>
        </>
    );
};
