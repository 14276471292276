import { FC, useEffect } from 'react';
import { assignAbTestingFlag } from 'components/abTesting/util';

export const CookieSetter: FC = () => {
    useEffect(() => {
        // 30% pouzivatelov
        assignAbTestingFlag('next-enabled-listing', 12, 6, 365);
    }, []);

    return null;
};
