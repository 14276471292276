import React, { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { LoginIcon } from 'components/layout/header/components/navbar/common/links/user/loggedOut/desktop/icon/LoginIcon';
import { SocialLoginMenu } from 'components/layout/header/components/navbar/common/links/user/loggedOut/menu/SocialLoginMenu';

interface IAddAdvertiser {
    text: string;
}

export const Login: FC<IAddAdvertiser> = ({text }) => {
    const { palette } = useTheme();

    return (
        <SocialLoginMenu>
            <Stack
                sx={{
                    '&:hover': {
                        bgcolor: palette.backgroundSecondary.main,
                    },
                    '&:active': {
                        bgcolor: palette.backgroundSecondary.main,
                        outline: `2px solid ${palette.backgroundPrimaryInverse.main}`,
                    },
                }}
                gap={0.75}
                paddingY={0.75}
                direction="row"
                paddingLeft={1}
                paddingRight={2}
                borderRadius={10}
                alignItems="center"
                justifyContent="center"
            >
                <LoginIcon />
                <Text variant="label1">{text}</Text>
            </Stack>
        </SocialLoginMenu>
    );
};
