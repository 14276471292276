import React, { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import { LoginIcon } from 'components/layout/header/components/navbar/common/links/user/loggedOut/mobile/icon/LoginIcon';
import { SocialLoginMenu } from 'components/layout/header/components/navbar/common/links/user/loggedOut/menu/SocialLoginMenu';

export const Login: FC = () => {
    const { palette } = useTheme();

    return (
        <SocialLoginMenu>
            <Stack
                p={0.5}
                borderRadius={10}
                alignItems="center"
                justifyContent="center"
                sx={{
                    '&:active': {
                        bgcolor: palette.backgroundSecondary.main,
                    },
                }}
            >
                <LoginIcon />
            </Stack>
        </SocialLoginMenu>
    );
};
