import { useParams } from 'next/navigation';
import { Box, Theme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import React, { FC, FunctionComponent, SVGProps } from 'react';
import { useSocialLogin } from 'modules/page/app/useSocialLogin';
import { ELoginMethod } from 'modules/gtmEvents/enums/login/ELoginMethod';
import { ELoginPosition } from 'modules/gtmEvents/enums/login/ELoginPosition';
import { LoginPositionRecords } from 'modules/gtmEvents/records/LoginPositionRecords';

interface ISocialLoginButton {
    name: string,
    providerUrl: string,
    icon: FunctionComponent<SVGProps<SVGSVGElement>>
    method: ELoginMethod,
    position: ELoginPosition,
}

export const SocialLoginItem: FC<ISocialLoginButton> = ({name, providerUrl, icon, method, position}) => {
    const params = useParams();

    const ga4positionFromOtherProjects
        = LoginPositionRecords[decodeURIComponent((params?.project || '').toString())];
    const loginPosition = ga4positionFromOtherProjects || position;

    const { onClick } = useSocialLogin(method, loginPosition, providerUrl);

    return (
        <Box
            gap={1}
            height={42}
            padding={1}
            width={'100%'}
            display="flex"
            borderRadius={1}
            onClick={() => {
                onClick();
            }}
            alignItems="center"
            sx={{border: (theme: Theme) => `1px solid ${theme.palette.labelQuarternary.main}`}}
        >
            <Svg icon component={icon} width={20} />
            <Text variant="label2" semibold>
                {name} prihlásenie
            </Text>

        </Box>
    );
};


